import React, { useState } from "react";
import { BuilderComponent, builder, useIsPreviewing } from "@builder.io/react";

import { Button, Spinner } from "../components";
import { Error404 } from "./404";

import withLayout from "../hoc/withLayout/withLayout";
import withClientRouteLoading from "../hoc/withClientRouteLoading/withClientRouteLoading";

const builderPreview = () => {
  // GENERAL STATES
  const isPreviewingInBuilder = useIsPreviewing();
  const [notFound, setNotFound] = useState(false);
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [slug, setSlug] = useState<string>();

  async function fetchContent(slug) {
    const content = await builder
      .get("page", {
        url: `/${slug}`,
      })
      .promise();

    setContent(content);
    setNotFound(!content);
    setLoading(false);
  }

  // If no page is found, return
  // a 404 page from your code.
  // The following hypothetical
  // <FourOhFour> is placeholder.
  if (notFound && !isPreviewingInBuilder) {
    return <Error404 />;
  }

  const handleClick = () => {
    if (!slug) return;
    fetchContent(slug);
    setLoading(true);
    setContent(null);
  };

  const handleKeypress = e => {
    if (e.keyCode === 13 || e.charCode === 13) {
      handleClick();
    }
  };

  return (
    <>
      <div className="border-b border-solid border-secondary-color px-4 py-10 md:px-10">
        <p className="p-styles mb-5">Enter the page you want to preview:</p>

        <div className="align-center mb-5 flex gap-x-10">
          <input
            onChange={e => setSlug(e.target.value)}
            type="text"
            className="w-1/3 rounded-md border border-solid border-outer-gray pl-7 pr-12 focus:border-outer-slate-blue focus:ring-outer-slate-blue"
            onKeyDown={handleKeypress}
          />
          <Button onClick={handleClick} disabled={!slug}>
            Preview
          </Button>
        </div>
      </div>
      {notFound && !isPreviewingInBuilder ? (
        <Error404 />
      ) : loading ? (
        <Spinner />
      ) : (
        // @ts-ignore
        <BuilderComponent model="page" content={content} />
      )}
    </>
  );
};

export default withLayout(withClientRouteLoading(builderPreview));
